import {
  ApiImageResource,
  Company,
  CompanyApi,
  EducationStreamApiModel,
  EducationStreamModel,
  ImageResourceModel,
  NonRequiredString,
  Nullable,
  PriceValueModel,
  TariffApiModel,
  TariffModel,
} from '../../../index';
import { DepartmentApi, DepartmentApiModel } from '../departments';
import { UserPosition, UserPositionApiModel } from '../user';
import { AxiosError } from 'axios';
import { CustomerCoursesResponseModel } from '../customer';

export enum UserRole {
  manager = 'ROLE_MANAGER',
  customer = 'ROLE_CUSTOMER',
  admin = 'ROLE_ADMIN',
}

export const UserRoleLabelMap = {
  [UserRole.manager]: 'HR',
  [UserRole.customer]: 'User',
  [UserRole.admin]: 'Admin',
};

export type PersonalApiModel = {
  avatar: Nullable<ApiImageResource>;
  email: string;
  anonymous_email?: string;
  first_name: string;
  is_disabled: boolean;
  is_fulfilled: boolean;
  last_name: string;
  patronymic: string;
  phone: string;
  uuid: string;
  last_activity_at: string;
  registered_at?: string;
  is_welcome_popup_viewed: boolean;
  education_streams?: Nullable<EducationStreamApiModel[]>;
};

export type CareerApiModel = {
  company_id: string;
  position: Nullable<UserPositionApiModel>;
  department: Nullable<DepartmentApiModel>;
  company_title: string;
  roles: UserRole[];
  is_verified: boolean;
  is_listing_hidden: boolean;
  is_listing_with_benefits_only: boolean;
  is_invite_link_available: boolean;
  is_company_without_manager: boolean;
  is_filters_hidden_on_listing: boolean;
  is_search_hidden_on_listing: boolean;
  is_bonus_points_hidden_on_listing: boolean;
  is_without_promo_cards: boolean;
  is_without_promo_banner: boolean;
  is_without_personal_tests: boolean;
  is_educational_streams_hidden_on_registration: boolean;
  is_prices_hidden_on_listing_cards: boolean;
  without_b2b2c_order_on_subscription: boolean;
  without_incorrect_phone_popup: boolean;
  without_personal_data_update: boolean;
  is_personal_data_fill_optional: boolean;
  tariff: Nullable<{
    data: TariffApiModel;
  }>;
  is_custom_compensation_available: boolean;
  lms_courses_count?: number;
  max_claims_count?: number;
  current_claims_count?: number;
};

export type BonusesApiModel = {
  points: string;
  bonus_price_conversion: {
    data: {
      money: {
        amount: string;
        currency: string;
      };
      points: string;
    };
  };
};

export type PersonalModel = {
  avatar: Nullable<ImageResourceModel>;
  email: string;
  anonymousEmail?: string;
  firstName: string;
  isDisabled: boolean;
  isFulfilled: boolean;
  lastName: string;
  patronymic: NonRequiredString;
  phone: string;
  uuid: string;
  lastActivityAt: Date | string;
  registeredAt: Nullable<string>;
  isWelcomePopupViewed: boolean;
  educationStreams: Nullable<EducationStreamModel[]>;
};

export type CareerModel = {
  position: Nullable<UserPosition>;
  companyTitle: string;
  companyId: string;
  department: Nullable<DepartmentApi>;
  isVerified: boolean;
  roles: UserRole[];
  tariff: Nullable<TariffModel>;
  regions?: string[];
  lmsCoursesCount?: number;
  isListingHidden?: boolean;
  isListingWithBenefitsOnly?: boolean;
  isCompanyWithoutManager?: boolean;
  isSearchHiddenOnListing?: boolean;
  isFiltersHiddenOnListing?: boolean;
  isBonusPointsHiddenOnListing?: boolean;
  isInviteLinkAvailable?: boolean;
  isWithoutPromoCards?: boolean;
  isWithoutPromoBanner?: boolean;
  isWithoutPersonalTests?: boolean;
  isEducationalStreamsHiddenOnRegistration?: boolean;
  isPricesHiddenOnListingCards?: boolean;
  withoutB2B2COrderOnSubscription?: boolean;
  isCustomCompensationAvailable?: boolean;
  withoutIncorrectPhonePopup?: boolean;
  isWithoutPersonalDataUpdate?: boolean;
  isPersonalDataFillOptional?: boolean;
};

export type BonusesModel = {
  points: number;
  bonusPriceConversion: {
    money: PriceValueModel;
    points: number;
  };
};

export type UserAggregateApiModel = {
  data: {
    personal: PersonalApiModel;
    bonuses: BonusesApiModel;
    career?: CareerApiModel;
    company: CompanyApi;
    me_courses: CustomerCoursesResponseModel;
  };
};

export type UserAggregateModel = {
  personal: PersonalModel;
  bonuses: BonusesModel;
  company: Nullable<Company>;
  career?: CareerModel;
  courses?: number;
};

export type UserState = {
  user: Nullable<UserAggregateModel>;
  status: UserStatusModel;
};

export type UserStatusModel = {
  isLoading: boolean;
  isLoaded: boolean;
  isReloadNeeded: boolean;
};

export type UserHookModel = {
  user: Nullable<UserAggregateModel>;
  status: UserStatusModel;
  reloadUser: () => void;
};

export type UserActions = {
  type: string;
  payload?: UserAggregateModel | AxiosError;
};
