import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useAppDispatch } from '@store/store.hooks';
import {
  CommonEventPositions,
  CourseModel,
  handleOpenPopup,
  IframeInitiator,
  logger,
  Nullable,
  PopupsEnum,
  QueryParams,
  requestClosePopup,
  useTranslations,
  useUser,
} from '@lerna-core';
import { ListFormsContainer } from '../coursesList';
import { useCoursesPageContext } from '../coursesPage';
import {
  CourseActiveContextModel,
  CourseActiveProviderProps,
} from './coursesActive.model';

const CoursesActiveContext =
  React.createContext<Nullable<CourseActiveContextModel>>(null);

export const CourseActiveContextProvider = ({
  children,
}: CourseActiveProviderProps): ReactElement => {
  const [course, setCourse] = useState<Nullable<CourseModel>>(null);
  const [courseIndex, setCourseIndex] = useState<Nullable<number>>(null);

  const { discountProgram, courses, selectedCourse } = useCoursesPageContext();
  const dispatch = useAppDispatch();
  const router = useRouter();
  const iframeInitiator = router.query[
    QueryParams.initiator
  ] as IframeInitiator;
  const discountProgramColors = iframeInitiator
    ? undefined
    : discountProgram?.colors;
  const translations = useTranslations();
  const { user } = useUser();

  const setNumericId = (numericId: string): void => {
    router.push(
      { query: { ...router.query, [QueryParams.numericId]: numericId } },
      undefined,
      {
        shallow: true,
      }
    );
  };

  const removeNumericId = (): void => {
    const nextQuery = { ...router.query };
    delete nextQuery[QueryParams.numericId];
    router.push({ query: nextQuery }, undefined, { shallow: true });
  };

  const handleSetActiveCourse = (
    course: CourseModel,
    courseIndex: number
  ): void => {
    setCourse(course);
    setCourseIndex(courseIndex);
  };

  const handleResetActiveCourse = (): void => {
    setCourse(null);
    setCourseIndex(null);
    removeNumericId();
  };

  useEffect(() => {
    const numericId = router.query[QueryParams.numericId];
    if (numericId) {
      const course = courses.find((course) => course.numericId == numericId);
      const activeCourse =
        selectedCourse?.numericId == numericId ? selectedCourse : course;

      if (activeCourse) {
        handleSetActiveCourse(activeCourse, 0);
        dispatch(
          handleOpenPopup(
            !!user
              ? PopupsEnum.userOrderFormPopup
              : PopupsEnum.guestOrderFormPopup,
            CommonEventPositions.coursesPageList
          )
        );
      }
    } else {
      dispatch(
        requestClosePopup(
          !!user
            ? PopupsEnum.userOrderFormPopup
            : PopupsEnum.guestOrderFormPopup
        )
      ).then(handleResetActiveCourse);
    }
  }, [router.query[QueryParams.numericId]]);

  const context = {
    setNumericId,
    removeNumericId,
  };

  return (
    <CoursesActiveContext.Provider value={context}>
      {children}
      <ListFormsContainer
        course={course}
        user={user}
        courseIndex={courseIndex}
        translations={translations}
        discountProgramColors={discountProgramColors}
      />
    </CoursesActiveContext.Provider>
  );
};

export const useCourseActiveContext = (): CourseActiveContextModel => {
  const value = useContext(CoursesActiveContext);

  if (!value) {
    logger.error(
      '[ERROR]: CourseActive: you cannot use context without a provider'
    );

    return {} as CourseActiveContextModel;
  }

  return value;
};
