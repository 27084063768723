import {
  CourseModel,
  Nullable,
  OpenPopupModel,
  TranslationModel,
} from '@lerna-core';
import * as Yup from 'yup';
import {
  ChangeEvent,
  Dispatch,
  FocusEvent,
  ReactNode,
  SetStateAction,
} from 'react';
import { FormikErrors, FormikTouched } from 'formik';

export type GuestOrderFormContainerProps = {
  course: CourseModel;
  translations: TranslationModel;
  eventPosition: string;
  courseIndex?: Nullable<number>;
};

export type GuestOrderFormInitialValues = {
  name: string;
  email: string;
  phone: string;
  withCompany: boolean;
  company: string;
  withPromoCode: boolean;
  promoCode: string;
  promoCodeDraft: string;
  privacy?: boolean;
  advConsent: boolean;
};

export type GuestOrderFormValidationSchema = Yup.ObjectSchema<
  Partial<Record<keyof GuestOrderFormInitialValues, Yup.AnySchema>>
>;

export type GuestOrderFormContextModel = GuestOrderFormContainerProps & {
  currentPopup: Nullable<OpenPopupModel>;
  handlePopupClose: () => void;
  handleFormSubmit: () => void;
  handleChange: (e: ChangeEvent<Element>) => void;
  values: GuestOrderFormInitialValues;
  errors: FormikErrors<GuestOrderFormInitialValues>;
  touched: FormikTouched<GuestOrderFormInitialValues>;
  isSubmitting: boolean;
  isSuccessDisplayed: boolean;
  isErrorDisplayed: boolean;
  isExistOrder: boolean;
  showFormStatus: boolean;
  setFieldValue: (field: string, value: string) => void;
  setErrors: (errors: FormikErrors<GuestOrderFormInitialValues>) => void;
  setTouched: (
    touched: FormikTouched<GuestOrderFormInitialValues>
  ) => Promise<FormikErrors<GuestOrderFormInitialValues>> | Promise<void>;
  isFreeCourse: boolean;
  handleBlur?: (e: FocusEvent<Element, Element>) => void;
  discountProgramQuery?: string;
  promoCodeQuery?: string;
  isApplyingPromoCode: boolean;
  setIsApplyingPromoCode: Dispatch<SetStateAction<boolean>>;
};

export type GuestOrderFormContextProviderProps = {
  props: GuestOrderFormContainerProps;
  children: ReactNode;
};

export enum GuestOrderFormSubmitStatusTypes {
  error = 'error',
  success = 'success',
  repeat = 'repeat',
}
