import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Nullable } from '../../../models';
import { getSimpleEvent, logger, pushDataLayer } from '../../../services';
import { RegisterPopupContainer } from './registerPopup.container';
import {
  RegisterPopupActionCallbacksModel,
  RegisterPopupContextModel,
  RegisterPopupFormValuesModel,
  RegisterPopupProviderProps,
} from './registerPopup.model';
import { useUser } from '../../user';
import {
  registerWelcomeClientProvider,
  RegisterWelcomeContainer,
} from '../registerWelcome';
import {
  canUseRegisterPopup,
  canUseRegisterWarning,
  canUseRegisterWelcome,
} from './registerPopup.helper';
import { CommonEventTypes } from '../../../analytics';
import { RegisterEvents, RegisterPositions } from '../register.analytics';

const RegisterPopupContext =
  React.createContext<Nullable<RegisterPopupContextModel>>(null);

export const RegisterPopupContextProvider = ({
  children,
  canClose = false,
  canShowWelcomePopup = false,
  departments = [],
  positions = [],
  currencies = [],
}: RegisterPopupProviderProps): ReactElement => {
  const { user } = useUser();
  const useRegisterPopup = canUseRegisterPopup(user);
  const useRegisterWelcome = canUseRegisterWelcome(user, canShowWelcomePopup);
  const useRegisterWarning = canUseRegisterWarning(user);

  const [formInitialValues, setFormInitialValues] =
    useState<RegisterPopupFormValuesModel>({
      firstName: user?.personal.firstName,
      lastName: user?.personal.lastName,
      phone: user?.personal.phone,
    });
  const [isRegisterOpened, setIsRegisterOpened] = useState<boolean>(false);
  const [registerInitiator, setRegisterInitiator] =
    useState<Nullable<RegisterPositions>>(null);
  const [isWelcomeOpened, setIsWelcomeOpened] = useState<boolean>(false);
  const [isWarningVisible, setIsWarningVisible] = useState<boolean>(false);
  const [actionCallbacks, setActionCallbacks] =
    useState<RegisterPopupActionCallbacksModel>({});

  const handleWarningVisibility = (visibility: boolean) => {
    if (useRegisterWarning) {
      setIsWarningVisible(visibility);
    }
  };

  const markRegisterWelcomeViewed = (): void => {
    if (user?.personal.isWelcomePopupViewed) return;

    registerWelcomeClientProvider().catch((error) => {
      logger.error('[ERROR]: can not post register welcome mark', error);
    });
  };

  const handleOpenWelcome = () => {
    if (useRegisterWelcome) {
      setIsWelcomeOpened(true);
      markRegisterWelcomeViewed();
    }
  };

  const handleCloseWelcome = () => {
    setIsWelcomeOpened(false);
  };

  const handleOpenRegister = (position: Nullable<RegisterPositions> = null) => {
    setIsRegisterOpened(true);
    markRegisterWelcomeViewed();
    setRegisterInitiator(position);

    pushDataLayer(
      getSimpleEvent(
        RegisterEvents.registrationOpen,
        CommonEventTypes.registration,
        position ? position : undefined
      )
    );
  };

  const handleCloseRegister = () => {
    setIsRegisterOpened(false);
    typeof actionCallbacks.onError === 'function' && actionCallbacks.onError();
    setActionCallbacks({});
    setRegisterInitiator(null);
  };

  const handleAction = (
    callbacks: RegisterPopupActionCallbacksModel,
    position: RegisterPositions,
    formValues?: RegisterPopupFormValuesModel
  ): void => {
    if (useRegisterPopup) {
      handleOpenRegister(position);
      setFormInitialValues({ ...formInitialValues, ...formValues });
      setActionCallbacks(callbacks);
    } else {
      typeof callbacks.onSuccess === 'function' && callbacks.onSuccess();
    }
  };

  const context = {
    currencies,
    actionCallbacks,
    handleAction,
    handleOpenRegister,
    handleCloseRegister,
    handleCloseWelcome,
    isRegisterOpened,
    isWelcomeOpened,
    formInitialValues,
    departments,
    positions,
    isWarningVisible,
    handleWarningVisibility,
    registerInitiator,
  };

  useEffect(() => {
    const isRegisterWelcomeViewed = user?.personal.isWelcomePopupViewed;

    if (!isRegisterWelcomeViewed && useRegisterWelcome) {
      setTimeout(
        () => {
          pushDataLayer(
            getSimpleEvent(
              RegisterEvents.welcomeAutoOpen,
              CommonEventTypes.registration
            )
          );
          handleOpenWelcome();
        },
        canClose ? 3000 : 0
      );
    } else if ((!isRegisterWelcomeViewed && useRegisterPopup) || !canClose) {
      setTimeout(
        () => {
          pushDataLayer(
            getSimpleEvent(
              RegisterEvents.registrationAutoOpen,
              CommonEventTypes.registration
            )
          );
          handleOpenRegister();
        },
        canClose ? 3000 : 0
      );
    } else if (useRegisterWarning) {
      handleWarningVisibility(true);
      pushDataLayer(
        getSimpleEvent(
          RegisterEvents.warningAutoOpen,
          CommonEventTypes.registration
        )
      );
    }
  }, []);

  return (
    <RegisterPopupContext.Provider value={context}>
      {children}
      {useRegisterWelcome && <RegisterWelcomeContainer canClose={canClose} />}
      {useRegisterPopup && <RegisterPopupContainer canClose={canClose} />}
    </RegisterPopupContext.Provider>
  );
};

export const useRegisterPopupContext = (): RegisterPopupContextModel => {
  const value = useContext(RegisterPopupContext);

  if (!value) {
    logger.error(
      '[ERROR]: RegisterPopupContext: you cannot use context without a provider'
    );

    return {} as RegisterPopupContextModel;
  }

  return value;
};
