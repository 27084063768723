import React, {
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import {
  browserService,
  CommonEventTypes,
  DeviceInfoContext,
  getCurrentPopupFromOpen,
  isMBMCompany,
  logger,
  Nullable,
  PopupsEnum,
  requestClosePopup,
} from '@lerna-core';
import { PageNameContext } from '@contexts';
import { useUserTariff } from '@hooks';
import {
  FormSubscriptionContentTypes,
  FormSubscriptionContextModel,
  FormSubscriptionContextProviderProps,
} from './formSubscription.model';
import {
  B2BOrderResponse,
  B2CFreeOrderResponse,
  B2COrderResponse,
  getFormOrderInfo,
  isConflictError,
  OrderFormEvents,
  orderFormPushDataLayer,
  OrderFormRequestTypes,
  withPromoCodeError,
} from '@features/common/forms';
import {
  getFormB2BWithoutCompensationInitialValues,
  getFormB2BWithoutCompensationValidationSchema,
  pushFormSubmitStatusEvent,
} from './formSubscription.helper';
import { sendUserOrderRequest } from '../userOrderForm.helper';
import {
  SubscriptionAvailabilityModel,
  subscriptionAvailabilityProvider,
} from '@features/common/subscriptionAvailability';
import { subscriptionClaimProvider } from '@features/common/subscriptionClaim';
import {
  UserOrderFormPaymentContentTypes,
  UserOrderFormSubmitStatusTypes,
} from '../userOrderForm.model';
import { useCourseActiveContext } from '@features/courses/coursesActive';
import { useAppDispatch } from '@store/store.hooks';

const FormSubscriptionContext =
  React.createContext<Nullable<FormSubscriptionContextModel>>(null);

export const FormSubscriptionContextProvider = ({
  props,
  children,
}: FormSubscriptionContextProviderProps): ReactElement => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const pageName = useContext(PageNameContext);
  const deviceInfoContext = useContext(DeviceInfoContext);
  const isMobile = browserService.mobileDetectFromContext(deviceInfoContext);
  const { removeNumericId } = useCourseActiveContext();
  const { isLernaPrime } = useUserTariff();
  const currentPopup = useSelector(
    getCurrentPopupFromOpen(PopupsEnum.userOrderFormPopup)
  );
  const formOrderInfo = getFormOrderInfo(props.course);

  const initialPaymentContent = !formOrderInfo.hasConsultation
    ? UserOrderFormPaymentContentTypes.onlinePay
    : UserOrderFormPaymentContentTypes.consultation;

  const [formContent, setFormContent] = useState<FormSubscriptionContentTypes>(
    FormSubscriptionContentTypes.subscription
  );
  const [paymentContent, setPaymentContent] =
    useState<UserOrderFormPaymentContentTypes>(initialPaymentContent);
  const [subscriptionAvailability, setSubscriptionAvailability] =
    useState<Nullable<SubscriptionAvailabilityModel>>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSuccessDisplayed, setIsSuccessDisplayed] = useState<boolean>(false);
  const [isErrorDisplayed, setIsErrorDisplayed] = useState<boolean>(false);
  const [isExistOrder, setIsExistOrder] = useState<boolean>(false);

  const fullAccessSubmitButtonTitle =
    paymentContent === UserOrderFormPaymentContentTypes.consultation
      ? props.translations?.send_request
      : props.translations?.go_to_payment;
  const isOnlinePaySelected =
    paymentContent === UserOrderFormPaymentContentTypes.onlinePay;
  const showFormStatus =
    (isSuccessDisplayed && !isOnlinePaySelected) ||
    isErrorDisplayed ||
    isExistOrder;
  const showSwitcherButton =
    !showFormStatus && !props.user.career?.isPricesHiddenOnListingCards;
  const isFreeCourse = props.course.isFree || props.course.isFreeByDiscount;
  const isSubscriptionFormContentSelected =
    formContent === FormSubscriptionContentTypes.subscription;
  const showAdvantages = !isMBMCompany(props.user.career?.companyId);

  const validationSchema = useMemo(
    () =>
      getFormB2BWithoutCompensationValidationSchema(
        props.translations,
        isOnlinePaySelected
      ),
    [isOnlinePaySelected]
  );
  const initialValues = useMemo(
    () => getFormB2BWithoutCompensationInitialValues(),
    []
  );

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    isSubmitting,
    errors,
    touched,
    setFieldValue,
    setErrors,
    setStatus,
    setTouched,
    setFieldError,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: async (values, actions): Promise<void> => {
      actions.setSubmitting(true);
      actions.setStatus({});

      if (isSubscriptionFormContentSelected) {
        await subscriptionClaimProvider(props.course.uuid)
          .then(() => onSuccess(true, null))
          .catch((error): void => {
            onError(false);
            logger.error(
              `[ERROR]: error sending request. ${error.response?.data}`
            );
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      } else {
        const promoCodeValue =
          !isOnlinePaySelected && values.withPromoCode
            ? values.promoCode
            : undefined;

        await sendUserOrderRequest(
          props.user,
          props.course,
          router,
          true,
          false,
          pageName,
          promoCodeValue,
          undefined,
          undefined,
          isOnlinePaySelected
        )
          .then((response) => onSuccess(!isOnlinePaySelected, response))
          .catch((error): void => {
            const conflictError = isConflictError(error);
            const promoCodeError = withPromoCodeError(error);

            if (promoCodeError) {
              setInputError(
                'promoCode',
                `${props.translations?.promocode_does_not_match}`
              );
              orderFormPushDataLayer(
                OrderFormEvents.formEnrollPromoCodeError,
                CommonEventTypes.coursesEvents,
                props.eventPosition,
                props.course
              );

              return;
            }
            if (conflictError) {
              onExistError(!isOnlinePaySelected);

              return;
            }

            onError(!isOnlinePaySelected);
            logger.error(
              `[ERROR]: error sending request. ${error.response?.data}`
            );
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }
    },
  });

  const isSubscriptionButtonDisabled =
    isSubmitting ||
    isLoading ||
    (isSubscriptionFormContentSelected &&
      !subscriptionAvailability?.isClaimAvailable);

  const clearData = (): void => {
    setErrors({});
    setStatus({});
    setTouched({});
    setFormContent(FormSubscriptionContentTypes.subscription);
    setPaymentContent(initialPaymentContent);
    setSubscriptionAvailability(null);
    setIsLoading(true);
    setFieldValue('withPromoCode', false);
    setFieldValue('promoCode', '');
    isSuccessDisplayed && setIsSuccessDisplayed(false);
    isErrorDisplayed && setIsErrorDisplayed(false);
    isExistOrder && setIsExistOrder(false);
  };
  const onSuccess = (
    withAnalytics = true,
    response: Nullable<
      B2COrderResponse | B2BOrderResponse | B2CFreeOrderResponse
    >
  ): void => {
    if (withAnalytics) {
      pushFormSubmitStatusEvent(
        UserOrderFormSubmitStatusTypes.success,
        props.course,
        props.eventPosition,
        isSubscriptionFormContentSelected,
        paymentContent,
        isFreeCourse,
        (response as B2COrderResponse)?.id ||
          (response as B2BOrderResponse)?.order?.uuid ||
          (response as B2CFreeOrderResponse)?.order?.dealId,
        props.user?.career?.companyId
          ? OrderFormRequestTypes.b2b
          : OrderFormRequestTypes.b2c
      );
    }
    if (values.withPromoCode) {
      orderFormPushDataLayer(
        OrderFormEvents.formEnrollPromoCode,
        CommonEventTypes.coursesEvents,
        props.eventPosition,
        props.course
      );
    }

    setIsSuccessDisplayed(true);

    const redirect = (response as B2BOrderResponse)?.payment?.url;
    if (redirect) {
      router.push(redirect);
    }
  };
  const onError = (withAnalytics = true): void => {
    if (withAnalytics) {
      pushFormSubmitStatusEvent(
        UserOrderFormSubmitStatusTypes.error,
        props.course,
        props.eventPosition,
        isSubscriptionFormContentSelected,
        paymentContent,
        isFreeCourse
      );
    }
    setIsErrorDisplayed(true);
  };
  const onExistError = (withAnalytics = true): void => {
    if (withAnalytics) {
      pushFormSubmitStatusEvent(
        UserOrderFormSubmitStatusTypes.exist,
        props.course,
        props.eventPosition,
        isSubscriptionFormContentSelected,
        paymentContent,
        isFreeCourse
      );
    }
    setIsExistOrder(true);
  };
  const setInputError = (inputName: string, value: string): void => {
    setFieldError(inputName, value);
  };
  const handlePopupClose = async (): Promise<void> => {
    orderFormPushDataLayer(
      OrderFormEvents.formEnrollClose,
      CommonEventTypes.coursesEvents,
      props.eventPosition,
      props.course,
      props.courseIndex
    );

    await dispatch(requestClosePopup(PopupsEnum.userOrderFormPopup)).then(
      () => {
        if (typeof removeNumericId === 'function') {
          removeNumericId();
        }
      }
    );
    clearData();
  };
  const handleChangePaymentContent = (
    content: keyof typeof UserOrderFormPaymentContentTypes
  ): void => {
    setPaymentContent(content as UserOrderFormPaymentContentTypes);
  };
  const handleFormSubmit = (): void => {
    isOnlinePaySelected &&
      orderFormPushDataLayer(
        OrderFormEvents.formEnrollGoToOnlinePay,
        CommonEventTypes.coursesEvents,
        props.eventPosition,
        props.course
      );
    handleSubmit();
  };
  const handleChangeFormContent = (
    content: FormSubscriptionContentTypes
  ): void => {
    setFormContent(content);
  };

  useEffect(() => {
    if (currentPopup && !subscriptionAvailability) {
      subscriptionAvailabilityProvider(props.course.uuid)
        .then((data) => {
          setSubscriptionAvailability(data);
        })
        .catch((err) => {
          logger.error('[ERROR]: can not get subscription availability', err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [subscriptionAvailability, currentPopup]);

  useEffect(() => {
    setPaymentContent(initialPaymentContent);
  }, [initialPaymentContent]);

  const context = {
    ...props,
    currentPopup,
    handlePopupClose,
    formContent,
    handleChangeFormContent,
    paymentContent,
    handleChangePaymentContent,
    subscriptionAvailability,
    fullAccessSubmitButtonTitle,
    handleFormSubmit,
    handleChange,
    handleBlur,
    values,
    isSubmitting,
    errors,
    touched,
    setFieldValue,
    isSuccessDisplayed,
    isErrorDisplayed,
    isExistOrder,
    showAdvantages,
    showFormStatus,
    showSwitcherButton,
    isOnlinePaySelected,
    isSubscriptionFormContentSelected,
    isFreeCourse,
    isLernaPrime,
    isMobile,
    isLoading,
    isSubscriptionButtonDisabled,
    formOrderInfo,
  };

  return (
    <FormSubscriptionContext.Provider value={context}>
      {children}
    </FormSubscriptionContext.Provider>
  );
};

export const useFormSubscriptionContext = (): FormSubscriptionContextModel => {
  const value = useContext(FormSubscriptionContext);

  if (!value) {
    logger.error('[ERROR]: you cannot use context without a provider');

    return {} as FormSubscriptionContextModel;
  }

  return value;
};
