import { CommonEventTypes, CourseModel, Translations } from '@lerna-core';
import * as Yup from 'yup';
import {
  getTextMaxLengthValidationMessage,
  getTextMinLengthValidationMessage,
  OrderFormEvents,
  orderFormPushDataLayer,
} from '@features/common/forms';
import {
  CustomCompensationFormInitialValues,
  CustomCompensationFormValidationSchema,
} from './customCompensationForm.model';
import {
  UserOrderFormPaymentContentTypes,
  UserOrderFormSubmitStatusTypes,
} from '../../userOrderForm.model';

export const getCustomCompensationFormInitialValues =
  (): CustomCompensationFormInitialValues => ({
    withPromoCode: false,
    promoCode: '',
    withCustomCompensation: false,
    comment: '',
  });

export function getCustomCompensationFormValidationSchema(
  translations: Translations,
  isOnlinePaySelected: boolean,
  isCommentPopupOpened: boolean
): CustomCompensationFormValidationSchema {
  const validationObject = {
    ...(!isOnlinePaySelected && {
      withPromoCode: Yup.boolean().optional(),
      promoCode: Yup.string().when('withPromoCode', {
        is: true,
        then: (promoCode) =>
          promoCode
            .required(translations?.input_required_error_label)
            .max(255, getTextMaxLengthValidationMessage(255, translations))
            .trim(),
      }),
      withCustomCompensation: Yup.boolean().optional(),
      ...(isCommentPopupOpened && {
        comment: Yup.string()
          .required(translations?.input_required_error_label)
          .min(5, getTextMinLengthValidationMessage(5, translations))
          .max(1000, getTextMaxLengthValidationMessage(1000, translations))
          .trim(),
      }),
    }),
  };

  return Yup.object().shape(validationObject);
}

export const pushFormSubmitStatusEvent = (
  type: UserOrderFormSubmitStatusTypes,
  course: CourseModel,
  eventPosition: string,
  paymentContent: UserOrderFormPaymentContentTypes,
  isFreeCourse: boolean,
  withCustomCompensation: boolean,
  orderId?: string,
  requestType?: string
): void => {
  let event = `${OrderFormEvents.formEnrollStatusValue
    .replace('{{type}}', type)
    .replace('{{value}}', 'free_compensation')}`;

  if (
    paymentContent === UserOrderFormPaymentContentTypes.consultation &&
    !isFreeCourse &&
    !withCustomCompensation
  ) {
    event = `${OrderFormEvents.formEnrollStatusValue
      .replace('{{type}}', type)
      .replace('{{value}}', 'consultation')}`;
  }
  if (isFreeCourse) {
    event = `${OrderFormEvents.formEnrollStatusValue
      .replace('{{type}}', type)
      .replace('{{value}}', 'free_course')}`;
  }

  orderFormPushDataLayer(
    event,
    CommonEventTypes.orderEvents,
    eventPosition,
    course,
    undefined,
    orderId,
    requestType
  );
};
