import styled from 'styled-components';
import { fontSettings } from '../../../styles';

export const FormAgreementTextStyled = styled.span<{ hasError?: boolean }>`
  color: ${({ theme, hasError }) =>
    !!hasError ? theme.COLOR.ERROR['900'] : '#5c5c5c'};
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_13, theme.FONT)};

  a {
    color: inherit;
    text-decoration: underline;
  }
`;

export const FormAgreementStyled = styled.div`
  margin: 16px 0 0;

  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

export const FormAgreementRowStyled = styled.div`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;
